const menuList = [
    {
        items: [
            // {
            //     label: "dashboard",
            //     icon: "pi pi-fw pi-home",
            //     to: "/"
            //     action: "read",
            //     subject: "dashboard"
            // },
            {
                label: "userModule",
                icon: "pi pi-fw pi-user",
                items: [
                    {
                        label: "pocketAdminList",
                        icon: "pi pi-fw pi-user-edit",
                        to: "/pocket-admin/list",
                        action: "view",
                        subject: "admin-list"
                    },
                    {
                        label: "roleModule",
                        icon: "pi pi-fw pi-user",
                        to: "/role/list",
                        action: "view",
                        subject: "role-list"
                    },
                    {
                        label: "merchantUserList",
                        icon: "pi pi-fw pi-users",
                        to: "/",
                        action: "view",
                        subject: "user-list"
                    }
                ],
                action: "manage",
                subject: "users"
            },
            {
                label: "brandModule",
                icon: "pi pi-fw pi-home",
                items: [
                    {
                        label: "brandList",
                        icon: "pi pi-fw pi-building",
                        to: "/brand/list",
                        action: "view",
                        subject: "brand-list"
                    },
                    {
                        label: "branchList",
                        icon: "pi pi-fw pi-home",
                        to: "/branch/list",
                        action: "view",
                        subject: "branch-list"
                    },
                    {
                        label: "pinList",
                        icon: "pi pi-fw pi-lock",
                        to: "/pin/list",
                        action: "view",
                        subject: "pin-list"
                    },
                    {
                        label: "reviews&ratings",
                        icon: "pi pi-fw pi-star",
                        to: "/reviews/brandlist/list",
                        action: "view",
                        subject: "feedback-list"
                    }
                ],
                action: "manage",
                subject: "brands"
            },
            {
                label: "brandModule",
                icon: "pi pi-fw pi-home",
                items: [
                    {
                        label: "reviews&ratings",
                        icon: "pi pi-fw pi-star",
                        to: "/reviews",
                        action: "manage",
                        subject: "individual-review"
                    }
                ],
                action: "manage",
                subject: "individual-review"
            },
            // {
            //     label: "branchModule",
            //     icon: "pi pi-fw pi-home",
            //     items: [
            //         {
            //             label: "branchList",
            //             icon: "pi pi-fw pi-home",
            //             to: "/branch/list",
            //             action: "manage",
            //             subject: "branches"
            //         },
            //         {
            //             label: "pinList",
            //             icon: "pi pi-fw pi-lock",
            //             to: "/pin/list",
            //             action: "manage",
            //             subject: "branches"
            //         }
            //     ],
            //     action: "manage",
            //     subject: "branches"
            // },

            /*TODO temporarily close*/
            /*{
                label: "membershipModule",
                icon: "pi pi-fw pi-id-card",
                to: "/membership/list",
                action: "manage",
                subject: "memberships"
            },*/
            {
                label: "notificationModule",
                icon: "pi pi-fw pi-bell",
                to: "/notification",
                // items: [
                //     {
                //         label: "Notification List",
                //         icon: "pi pi-fw pi-send",
                //         to: "/notification"
                //     }
                // {
                //     label: "sendNoti",
                //     icon: "pi pi-fw pi-send",
                //     to: "/notification/send"
                // },
                // {
                //     label: "sendBulkNoti",
                //     icon: "pi pi-fw pi-inbox",
                //     to: "/notification/bulk-import"
                // }
                // ],
                action: "view",
                subject: "notification-list"
            },
            // {
            //     label: "bannnerModule",
            //     icon: "pi pi-fw pi-image",
            //     to: "/banners/manage",
            //     action: "manage",
            //     subject: "banners"
            // },
            {
                label: "couponModule",
                icon: "pi pi-fw pi-credit-card",
                to: "/coupon/list",
                action: "view",
                subject: "coupon-list"
            },
            {
                label: "membershipModule",
                icon: "pi pi-fw pi-id-card",
                to: "/membership/list",
                action: "view",
                subject: "membership-list"
            },

            {
                label: "appConfiguration",
                icon: "pi pi-fw pi-cog",
                items: [
                    {
                        label: "rewardsBalance",
                        icon: "pi pi-fw pi-eye",
                        to: "/app-configuration/reward-balances",
                        action: "view",
                        subject: "reward-balance-list"
                    }
                ],
                action: "manage",
                subject: "app-configuration"
            },
            {
                label: "luckyDrawWebAppModule",
                icon: "pi pi-fw pi-credit-card",
                to: "/lucky-draw-web-app/list",
                action: "view",
                subject: "luckydraw-list"
            },
            {
                label: "campaignModule",
                icon: "pi pi-fw pi-credit-card",
                items: [
                    {
                        label: "raffleCampaign",
                        icon: "pi pi-fw pi-credit-card",
                        to: "/campaign/raffle/list",
                        action: "view",
                        subject: "raffle-list"
                    }
                ],
                action: "manage",
                subject: "campaigns"
            },
            {
                label: "activityLogsModule",
                icon: "pi pi-fw pi-chart-bar",
                to: "/activity/logs",
                action: "",
                subject: ""
            },
            {
                label: "dashboardMenu",
                icon: "pi pi-fw pi-desktop",
                items: [
                    {
                        label: "transactions",
                        icon: "pi pi-fw pi-arrow-right-arrow-left",
                        to: "/dashboard/transactions",
                        action: "",
                        subject: ""
                    },
                    {
                        label: "userFrequency",
                        icon: "pi pi-fw pi-wave-pulse",
                        to: "/dashboard/user-frequency",
                        action: "",
                        subject: ""
                    },
                    {
                        label: "campaignPerformance",
                        icon: "pi pi-fw pi-calendar",
                        to: "/dashboard/performance/campaign",
                        action: "",
                        subject: ""
                    },
                    {
                        label: "branchPerformance",
                        icon: "pi pi-fw pi-shop",
                        to: "/dashboard/performance/branch",
                        action: "",
                        subject: ""
                    }
                ],
                action: "",
                subject: ""
            }
        ]
    }
]

export default menuList
